import React, {useEffect} from "react";
import {get, isEmpty} from "lodash";
import {connect} from "react-redux";
import ReactTooltip from "react-tooltip";

import {createListAction} from "../../redux/actions";
import {APPROVED_USER, NEW_SIGNALS} from "../../redux/actions/constants";
import './style.css';
import { NavLink } from "react-router-dom";

const Header = ({history, user, usersToApprove, getUsersToApprove}) => {

    return (
        <div className="app-header header-shadow">
            <div className="app-header__logo">
                <div className="logo-src"/>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic"
                                data-class="closed-sidebar">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                <span>
                    <button type="button"
                            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"/>
                        </span>
                    </button>
                </span>
            </div>
            <div className="app-header__content">
                <div className="app-header-left">
                    <div className="header-btn-lg cursor-header pr-0">
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left  ml-3 header-user-info" data-toggle="dropdown"
                                     aria-haspopup="true" aria-expanded="false">
                                    <div className="widget-heading">
                                        Sélection de l'app
                                        <i className="fa fa-angle-down ml-2 opacity-8"/>
                                    </div>
                                    <div tabIndex="-1" role="menu" aria-hidden="true"
                                         className="dropdown-menu dropdown-menu-right">
                                        <button type="button" className="dropdown-item">Mouqabala</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-header-right">
                    <div className="header-btn-lg cursor-header pr-0">
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left  ml-3 header-user-info" data-toggle="dropdown"
                                     aria-haspopup="true" aria-expanded="false">
                                    <a className="widget-heading">
                                        {!isEmpty(user) && user.lastName + " " + user.firstName}
                                        <i className="fa fa-angle-down ml-2 opacity-8"/>
                                    </a>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item" href="#">Profil</a>
                                    <div className="dropdown-divider"></div>
                                    <NavLink className="dropdown-item" to="/logout">
                                        Déconnexion
                                    </NavLink> 
                               </div>
                                {/*
                                <div className="widget-content-right header-user-info ml-3">
                                    {
                                        usersToApprove.length ?
                                            <>
                                                <p data-tip="hello world">
                                                    <button type="button"
                                                            className="btn-shadow p-1 btn btn-primary btn-sm mt-3">
                                                        <i className="fa text-white fa-check pr-1 pl-1"/>
                                                        <span className="fa fa-comment"/>
                                                        <span className="num">{usersToApprove.length}</span>
                                                    </button>
                                                </p>
                                                <ReactTooltip place="left" type="error" effect="float"/>
                                            </> :
                                            <button type="button" className="btn-shadow p-1 btn btn-secondary btn-sm">
                                                <i className="fa text-white fa-check pr-1 pl-1"/>
                                            </button>
                                    }
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    usersToApprove: get(state, 'app.approved.data', []),
    newSignals: get(state, 'app.newSignals.data', [])
});

export default connect(mapStateToProps, {
    getUsersToApprove: createListAction(APPROVED_USER),
    getnewSignals: createListAction(NEW_SIGNALS),
})(Header);
