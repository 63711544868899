import React, {useEffect} from "react";
import {get, isEmpty} from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {numericality, required} from "redux-form-validators";

import {createListAction, createPutAction} from "../../redux/actions";
import {FLASH_PLANS, PLANS, PLANS_SETTINGS, TARIFS} from "../../redux/actions/constants";
import {renderInputField, renderToggleInput} from "../../components/Redux-Forms";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {adaptTarifsInitialValues} from "./dataAdapter";
import Loader from "../../Layout/Loader";

const Tarifs = (props) => {

    useEffect(() => {
        props.getPlans();
        props.getFlashPlans();
        props.getPlansSettings();
    }, []);

    useEffect(() => {
        console.log("result", props.result);
    }, [props.result]);

    const createOrUpdatePlans = (values) => {
        props.saveTarifs(null, values, () => {
            props.getPlans();
            props.getFlashPlans();
            props.getPlansSettings();
        });
    };

    if (isEmpty(props.initialValues)) return <Loader/>;

    return (
        <div className="row">
            <div className="col-lg-12">
                <form onSubmit={props.handleSubmit(createOrUpdatePlans)}>
                    <div className='my-4 row'>
                        <div className='col-6'>
                            <div className='card'>
                                <h4 className="card-header">Abonnement gratuit</h4>
                                <div className='card-body m-4'>
                                    <div className='row'>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Swipes par jour</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="settings.maxSwipes"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Swipes par jour"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Flash par jour</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="settings.freeFlash"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Flash par jour"
                                                    validate={[required(), numericality({
                                                        '>=': 0,
                                                        msg: "Doit être positif"
                                                    })]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='card'>
                                <h4 className="card-header">Période d'essai</h4>
                                <div className='card-body m-4'>
                                    <div className='row'>
                                        <div className='form-group col-md-12 col-sm-12'>
                                            <label className='font-weight-bold'>Durée de la période d'essai</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="settings.essayPeriod"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Durée de la période d'essai"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='my-4 row'>
                        <div className='col-12'>
                            <div className='card'>
                                <h4 className="card-header">Abonnements</h4>
                                <div className='card-body m-4'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i
                                                        className="fas fa-calendar-minus"/></InputGroupText>
                                                    <InputGroupText>1 mois = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="oneMonth.pricePerMonth"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="oneMonth.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="oneMonth.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="oneMonth.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i
                                                        className="fas fa-calendar-plus"/></InputGroupText>
                                                    <InputGroupText>3 mois = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="threeMonth.pricePerMonth"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€ par mois</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="threeMonth.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="threeMonth.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="threeMonth.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i
                                                        className="fas fa-calendar-times"/></InputGroupText>
                                                    <InputGroupText>12 mois = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="year.pricePerMonth"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€ par mois</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="year.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="year.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="year.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='my-4 row'>
                        <div className='col-12'>
                            <div className='card'>
                                <h4 className="card-header">Flashs</h4>
                                <div className='card-body m-4'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i
                                                        className="fas fa-space-shuttle"/></InputGroupText>
                                                    <InputGroupText>1 Flash = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="oneFlash.price"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="oneFlash.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="oneFlash.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="oneFlash.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>5 x <i
                                                        className="fas fa-space-shuttle pl-1"/></InputGroupText>
                                                    <InputGroupText>5 Flashs = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="fiveFlash.price"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="fiveFlash.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="fiveFlash.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="fiveFlash.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-4'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>10 x<i
                                                        className="fas fa-space-shuttle pl-1"/></InputGroupText>
                                                    <InputGroupText>10 Flashs = </InputGroupText>
                                                </InputGroupAddon>
                                                <Field
                                                    className='form-control'
                                                    name="tenFlash.price"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Prix"
                                                    validate={[required(), numericality({
                                                        '>': 0,
                                                        msg: "Doit être supérieur à 0"
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>€</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <Field
                                                            className='form-control'
                                                            name="tenFlash.isPromo"
                                                            component={renderToggleInput}
                                                            type="checkbox"
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="PROMOTION" disabled/>
                                            </InputGroup>
                                        </div>
                                        <div className='col-3'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="tenFlash.percentage"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Pourcentage"
                                                    validate={[numericality({
                                                        '>': 1,
                                                        '<=': 100,
                                                        int: true,
                                                        msg: "Doit être entre 1 et 100",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className='col-2'>
                                            <InputGroup>
                                                <Field
                                                    className='form-control'
                                                    name="tenFlash.days"
                                                    component={renderInputField}
                                                    type="number"
                                                    label="Jours"
                                                    validate={[numericality({
                                                        '>': 0,
                                                        int: true,
                                                        msg: "Doit être supérieur à 0",
                                                        allowBlank: true
                                                    })]}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>jours</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className=' col-12 d-flex flex-row-reverse mt-3'>
                            <button
                                className={'btn btn-primary ml-1'}
                                type="submit"
                                disabled={
                                    props.pristine ||
                                    props.submitting
                                }
                            >
                                Enregistrer
                            </button>
                            <button
                                className={'btn btn-secondary ml-1'}
                                type="button"
                                disabled={
                                    props.pristine ||
                                    props.submitting
                                }
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        plans: get(state, 'app.plans.data', []),
        plans_settings: get(state, 'app.plans_settings.data', []),
        flash_plans: get(state, 'app.flash_plans.data', []),
        result: get(state, 'app.tarifs.one', {}),
        initialValues: adaptTarifsInitialValues(get(state, 'app.plans.data', []), get(state, 'app.plans_settings.data', []), get(state, 'app.flash_plans.data', []))
    }
};

export default compose(
    connect(() => mapStateToProps, {
        getPlans: createListAction(PLANS),
        getFlashPlans: createListAction(FLASH_PLANS),
        getPlansSettings: createListAction(PLANS_SETTINGS),
        saveTarifs: createPutAction(TARIFS),
    }),
    reduxForm({form: 'Tarifs', enableReinitialize: true}),
)(Tarifs);
