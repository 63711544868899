import React from 'react';

const NavBarPub = ({actualPub, changePub}) => {

    const pubs = [
        {
            id: "1",
            libelle: "Pub 1"
        },
        {
            id: "2",
            libelle: "Pub 2"
        },
        {
            id: "3",
            libelle: "Pub 3"
        },
        {
            id: "4",
            libelle: "Pub 4"
        },
    ]
    return ( 
        <div>
            <ul className="nav nav-tabs card-header-tabs">
                {pubs.map(pub =>
                    <li key={"pub-" +pub.id} className="nav-item">
                        <a id={pub.id} className={"nav-link" + (actualPub === pub.id ? " active" : "")} onClick={changePub}>{pub.libelle}</a>
                    </li>
                )}
            </ul>
        </div>
     );
}
 
export default NavBarPub;