import React from 'react';
import moment from "moment";

export default [
    {
        name: 'username',
        label: "Nom d'utilisateur",
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'birthday',
        label: 'Date de naissance',
        options: {
            filterType: "textField",
            customBodyRender: v => moment(v.date).format("DD / MM / YYYY")
        }
    },
    {
        name: 'liveCountry',
        label: 'Pays',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "phone",
        label: 'Numéro de téléphone',
        options: {
            filterType: "textField",
            customCsvRender: v => "Tel : " + v
        }
    },
    {
        name: "status",
        label: 'Statut',
        options: {
            filterType: "multiselect",
            customBodyRender: v => {
                if (v === 'Banni') return <span style={{color: 'red'}} > <i className="fas fa-ban"/> Banni</span>;
                else if (v === 'Certifié') return <span style={{color: 'green'}} > <i className="fas fa-check-double"/> Certifié</span>;
                else return 'Non Certifié';
            },
            customCsvRender: v => v
        }
    },
];
