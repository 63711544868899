export default [
    {
        name: 'Admins',
        url: '/admins',
        icon: 'fa fa-user-lock'
    },
    {
        name: 'Utilisateurs',
        url: '/utilisateurs',
        icon: 'fa fa-users'
    },
    {
        name: 'Validations',
        url: '/validations',
        icon: 'fa fa-check-double'
    },
    {
        name: 'Modération',
        url: '/moderation',
        icon: 'fa fa-ban'
    },
    {
        name: 'Grille tarifaire',
        url: '/grille',
        icon: 'fa fa-money-bill-wave'
    },
    {
        name: 'Communication',
        url: '/communication',
        icon: 'fa fa-comments'
    },
    {
        name: 'Publicités',
        url: '/publicites',
        icon: 'fa fa-ad'
    },
];
