import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import ImageGallery from "react-image-gallery";
import Toggle from "react-toggle";
import moment from "moment";

import "./style.css";
import {
  createGetAction,
  createListAction,
  createPutAction,
} from "../../redux/actions";
import {
  ABONNEMENTS,
  MOUQABILS,
  PLANS_SETTINGS,
} from "../../redux/actions/constants";
import { REACT_APP_MEDIA_URL } from "../../const";
import Loader from "../../Layout/Loader";
import swal from "sweetalert";

const Utilisateur = ({
  utilisateur,
  planSettings,
  history,
  computedMatch,
  getUtilisateur,
  updateUtilisateur,
  updateAbonnement,
  getPlanSettings,
}) => {
  const [images, setImages] = useState([]);
  const [essayPeriod, setEssayPeriod] = useState();
  useEffect(() => {
    getUtilisateur({ id: get(computedMatch, "params.id") });
    getPlanSettings();
  }, [computedMatch, getPlanSettings, getUtilisateur]);

  useEffect(() => {
    const pics = [];
    if (utilisateur.imagePrincipal) {
      pics.push({
        original: REACT_APP_MEDIA_URL + utilisateur.imagePrincipal.filePath,
        thumbnail: REACT_APP_MEDIA_URL + utilisateur.imagePrincipal.filePath,
      });
    }
    if (utilisateur.secondImage) {
      pics.push({
        original: REACT_APP_MEDIA_URL + utilisateur.secondImage.filePath,
        thumbnail: REACT_APP_MEDIA_URL + utilisateur.secondImage.filePath,
      });
    }
    if (utilisateur.thirdImage) {
      pics.push({
        original: REACT_APP_MEDIA_URL + utilisateur.thirdImage.filePath,
        thumbnail: REACT_APP_MEDIA_URL + utilisateur.thirdImage.filePath,
      });
    }
    setImages(pics);
  }, [utilisateur]);

  useEffect(() => {
    console.log(planSettings);
    planSettings[0] && setEssayPeriod(planSettings[0].essayPeriod);
  }, [planSettings]);

  const banorDebanUser = () => {
    const user = utilisateur;
    if (user.isSuspended) {
      swal({
        title: "Êtes-vous sûr?",
        text:
          "Voulez-vous vraiment annuler le bannissement de l'utilisateur " +
          user.username +
          " ?",
        icon: "warning",
        buttons: {
          cancel: "Annuler",
          terminer: true,
        },
      }).then((willBan) => {
        if (willBan) {
          updateUtilisateur(
            { id: user.id },
            { isSuspended: false, isApproved: false },
            () => {
              getUtilisateur({ id: user.id });
              swal(
                "L'interdiction de l'utilisateur " +
                  user.username +
                  " est terminée!",
                {
                  icon: "success",
                }
              );
            }
          );
        }
      });
    } else
      swal({
        title: "Êtes-vous sûr?",
        text:
          "Voulez-vous vraiment bannir l'utilisateur " + user.username + " ?",
        icon: "warning",
        buttons: {
          cancel: "Annuler",
          bannir: true,
        },
      }).then((willBan) => {
        if (willBan) {
          updateUtilisateur(
            { id: user.id },
            { isSuspended: true, isApproved: true },
            () => {
              getUtilisateur({ id: user.id });
              swal("L'utilisateur a été banni!", {
                icon: "success",
              });
              // go to validation list
              history.push("/validations");
            }
          );
        }
      });
  };

  const giveEssai = () => {
    const abonnement = {
      id: utilisateur.abonnement.id,
      isPaid: true,
      dateDebut: moment(),
      dateFin: moment().add(essayPeriod, "days"),
    };
    updateAbonnement({ id: abonnement.id }, abonnement, () => {
      getUtilisateur({ id: utilisateur.id });
      swal(
        "Une période d'essai de " +
          essayPeriod +
          " jours a été donnée à l'utilisateur",
        {
          icon: "success",
        }
      );
    });
  };

  const handleValidChange = (value) => {
    const message = value.target.checked
      ? "L'utilisateur a été certifié"
      : "La certification de l'utilisateur a été annulée";
    updateUtilisateur(
      { id: utilisateur.id },
      { isValid: value.target.checked },
      () => {
        getUtilisateur({ id: utilisateur.id });
        swal(message, {
          icon: "success",
        });
      }
    );
  };

  const approveUser = (id) => {
    swal({
      title: "Êtes-vous sûr?",
      text: "Une fois validé, vous ne pouvez pas annuler cette action!",
      icon: "success",
      buttons: {
        cancel: "Annuler",
        valider: true,
      },
    }).then((willDelete) => {
      if (willDelete) {
        updateUtilisateur(
          { id: utilisateur.id },
          { edit: false, isApproved: true, editedField: null },
          () => {
            swal("L'utilisateur a été validé!", {
              icon: "success",
            });
            // go to user list
            history.push("/validations");
          }
        );
      }
    });
  };

  if (isEmpty(utilisateur)) return <Loader />;

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="my-4 row">
          <div className="col-8">
            <div className="card">
              <h4 className="card-header">{"Photos du profil"}</h4>
              <div className="card-body m-4">
                <div className="row">
                  <div className="col-12">
                    <ImageGallery
                      items={images}
                      showPlayButton={false}
                      showBullets={true}
                      thumbnailPosition={"left"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <h4 className="card-header d-flex justify-content-center">
                {utilisateur.username +
                  ", " +
                  moment().diff(moment(utilisateur.birthday), "years")}
              </h4>
              <div className="card-body m-2">
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">À propos :</div>
                  <div className="col-7 pl-0">{utilisateur.aboutMe}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Sexe :</div>
                  <div className="col-7 pl-0">
                    {utilisateur.gender === "M" ? "Homme" : "Femme"}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Abonnement :</div>
                  <div className="col-7 pl-0">
                    {utilisateur.abonnement.isPaid
                      ? `Termine le ${moment(
                          utilisateur.abonnement.dateFin
                        ).format("DD / MM / YYYY")}`
                      : `Gratuit`}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Téléphone :</div>
                  <div className="col-7 pl-0">{utilisateur.phone}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Location :</div>
                  <div className="col-7 pl-0">
                    {utilisateur.liveCity + ", " + utilisateur.liveCountry}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Origine :</div>
                  <div className="col-7 pl-0">{utilisateur.bornCountry}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Likes :</div>
                  <div className="col-7 pl-0">{utilisateur.likes.length}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">
                    Liked {utilisateur.gender === "M" ? "him" : "her"} :
                  </div>
                  <div className="col-7 pl-0">{utilisateur.likedMe.length}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-4 pr-0 secondary-text">Ses projets :</div>
                  <div className="col-7 pl-0">{utilisateur.myProjects}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Toggle
                      defaultChecked={!!utilisateur.isValid}
                      onChange={handleValidChange}
                    />
                    <span className="label-text pl-3">
                      {!!utilisateur.isValid
                        ? "Ce profil a été vu physiquement par nos services"
                        : "Ce profil n'a pas été vu physiquement par nos services"}
                    </span>
                  </div>
                </div>
                {!utilisateur.isApproved && (
                  <div className="row mt-3">
                    <div className="col-12">
                      <div
                        className="btn btn-success w-100"
                        onClick={approveUser}
                      >
                        Approuver l'utilisateur
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mt-3">
                  <div className="col-12">
                    <div
                      className="btn btn-danger w-100"
                      onClick={banorDebanUser}
                    >
                      {utilisateur.isSuspended
                        ? "Annuler le bannissement"
                        : "Bannir"}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <button
                      className="btn btn-info w-100"
                      disabled={utilisateur.abonnement.isPaid}
                      onClick={giveEssai}
                    >
                      {utilisateur.abonnement.isPaid
                        ? "Abonné(e)"
                        : "Proposer ESSAI de " + essayPeriod + " jours"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    utilisateur: get(state, "app.mouqabils.one", {}),
    planSettings: get(state, "app.plans_settings.data", []),
  };
};

export default connect(mapStateToProps, {
  getUtilisateur: createGetAction(MOUQABILS),
  getPlanSettings: createListAction(PLANS_SETTINGS),
  updateUtilisateur: createPutAction(MOUQABILS),
  updateAbonnement: createPutAction(ABONNEMENTS),
})(Utilisateur);
