import Utilisateurs from './index';
import Utilisateur from './show';

export default [
    {
        name: "Utilisateurs",
        path: "/utilisateurs",
        exact: true,
        component: Utilisateurs
    },
    {
        name: "Utilisateur",
        path: "/utilisateurs/details/:id",
        exact: true,
        component: Utilisateur
    },
]
