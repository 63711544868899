import Validations from './index';

export default [
    {
        name: "Validation",
        path: "/validations",
        exact: true,
        component: Validations
    }
]
