import axios from 'axios';
import {get} from 'lodash';
import {toast} from "react-toastify";
import {REACT_APP_API_URL} from "../const";

const instance =  axios.create({
    baseURL: REACT_APP_API_URL,
});

instance.interceptors.request.use(async request => {
    const token = await localStorage.getItem("token");
    if (token) {
        request.headers.common['Authorization'] = "Bearer " + token;
    }

    return request;
});

instance.interceptors.response.use(response => response, error => {
    const status = get(error, 'response.status', undefined);
    const message = get(error, 'response.data.message', "");

    if (!status || status >= 500) {
        toast.error('Une erreur inattendue s\'est produite lors de votre demande. Veuillez réessayer plus tard.');
    }
    if (status === 401 && message.toLowerCase().includes("jwt")) {
        console.log("HERE");
        localStorage.clear();
        toast.info("Votre session a expiré. Veuillez vous re-connecter SVP");
        window.location.href = '/login';
    }

    return Promise.reject(error);
});

export default instance;
