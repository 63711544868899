import React, {useEffect} from 'react';
import {get} from "lodash";
import {connect} from "react-redux";

import Loader from "../../Layout/Loader";
import Datatable from "../../components/Datatable";
import columns from "./columns";
import {createDeleteAction, createListAction} from "../../redux/actions";
import {ADMINS, USERS} from "../../redux/actions/constants";
import swal from "sweetalert";

const Admins = ({admins, history, getAdmins, deleteAdmin}) => {
    useEffect(() => {
        getAdmins();
    }, []);

    const actions = [
        {
            action: 'edit',
            icon: ' fa fa-pen',
            color: 'warning',
            name: 'Modifier',
            onClick: (id) => history.push("/admins/modifier/" + id)
        },
        {
            action: 'delete',
            icon: 'fa fa-trash-alt',
            name: 'Supprimer',
            color: 'danger',
            onClick: (id) => confirmDeleteAdmin(id)
        }
    ];

    const confirmDeleteAdmin = (id) => {
        swal({
            title: "Êtes-vous sûr?",
            text: "Une fois supprimé, vous ne pourrez plus récupérer cet admin!",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                supprimer: true
            }
        }).then((willDelete) => {
            if (willDelete) {
                deleteAdmin({id}, () => {
                    getAdmins();
                    swal("L'admin a été supprimé!", {
                        icon: "success",
                    });
                });
            }
        });
    };

    if (!Array.isArray(admins)) return <Loader/>;

    return (
        <div className="row">
            <div className="col-lg-12">
                <Datatable data={admins}
                           title={"Gestion des admins"}
                           columns={columns}
                           actions={actions}
                           options={{
                               customToolbar: () => {
                                   return (
                                       <button className="btn btn-primary"
                                               onClick={() => history.push("/admins/ajouter")}
                                       >
                                           <i className="fa fa-plus mr-2"/>
                                           Ajouter
                                       </button>
                                   );
                               },
                               selectableRows: 'none'
                           }}/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        admins: get(state, 'app.admins.data', [])
    }
};

export default connect(mapStateToProps, {getAdmins: createListAction(ADMINS), deleteAdmin: createDeleteAction(USERS)})(Admins);
