import Publicites from './index';

export default [
    {
        name: "Publicités",
        path: "/publicites",
        exact: true,
        component: Publicites
    }
]
