import React from 'react';

const Sample = ({getTest, history}) => {
    //history.push("/admins")
    return(
    <div className="row">
        <div className="col-lg-12">
        </div>
    </div>)
}



export default Sample;
