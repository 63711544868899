import React, {useEffect} from 'react';
import {get} from "lodash";
import {connect} from "react-redux";

import Loader from "../../Layout/Loader";
import Datatable from "../../components/Datatable";
import {newUsersColumns, editUsersColumns} from "./columns";
import {createListAction, createPutAction} from "../../redux/actions";
import {APPROVED_USER, EDITED_USER, MOUQABILS} from "../../redux/actions/constants";
import swal from "sweetalert";

const Validations = ({usersThatEdit, history, usersToApprove, updateUtilisateur, getUsersToApprove, getUsersThatEdit}) => {

    useEffect(() => {
        getUsersToApprove();
        getUsersThatEdit();
    }, []);

    const actions = [
        {
            action: 'show',
            icon: ' fa fa-eye',
            color: 'info',
            name: 'Afficher',
            onClick: (id) => history.push('/utilisateurs/details/' + id)
        },
        {
            action: 'approve',
            icon: 'fa fa-check',
            name: 'Valider',
            color: 'success',
            onClick: (id) => approveUser(id)
        }
    ];

    const approveUser = (id) => {
        swal({
            title: "Êtes-vous sûr?",
            text: "Une fois validé, vous ne pouvez pas annuler cette action!",
            icon: "success",
            buttons: {
                cancel: "Annuler",
                valider: true
            }
        }).then((willDelete) => {
            if (willDelete) {
                updateUtilisateur({id},
                    {edit: false, isApproved: true, editedField: null}
                    ,() => {
                    getUsersToApprove();
                    getUsersThatEdit();
                    swal("L'utilisateur a été validé!", {
                        icon: "success",
                    });
                });
            }
        });
    };

    if (!Array.isArray(usersThatEdit) || !Array.isArray(usersToApprove)) return <Loader/>;

    return (
        <>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <Datatable data={usersToApprove}
                               title={"Nouveaux utilisateurs à approuver"}
                               columns={newUsersColumns}
                               actions={actions}
                               options={{
                                   selectableRows: 'none'
                               }}
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <Datatable data={usersThatEdit}
                               title={"Nouvelles modifications des utilisateurs"}
                               columns={editUsersColumns}
                               actions={actions}
                               options={{
                                   selectableRows: 'none'
                               }}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        usersThatEdit: get(state, 'app.edits.data', []),
        usersToApprove: get(state, 'app.approved.data', [])
    }
};

export default connect(mapStateToProps, {
    getUsersToApprove: createListAction(APPROVED_USER),
    getUsersThatEdit: createListAction(EDITED_USER),
    updateUtilisateur: createPutAction(MOUQABILS)
})(Validations);
