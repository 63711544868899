import React, { useEffect, useState } from 'react';
import NavBarPub from './components/NavBarPub';
import "./index.css"
import { postFile } from '../../redux/services';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get, toInteger } from 'lodash';
import { createListAction, createPutAction } from '../../redux/actions';
import {  PUBS } from '../../redux/actions/constants';
import Loader from '../../Layout/Loader';
import { REACT_APP_MEDIA_URL } from '../../const';
import { toast } from 'react-toastify';

const Publicites = ({getPubs, pubs, updatePubs}) => {

    useEffect(() => {
        getPubs()
    },[])
    console.log(pubs)

    const [actualPub, setActualPub] = useState({id:"1", file: false})
    const [imagePub, setImagePub] = useState("")
    const [imageSimulatePub, setSimulateImagePub] = useState("")

    useEffect(() => {
        let exist = false
        pubs.map(pub => {
            console.log(pub.id === actualPub.id)
            if(pub.id === toInteger(actualPub.id)){
                setImagePub(REACT_APP_MEDIA_URL + pub.image.filePath)
                console.log(REACT_APP_MEDIA_URL + pub.image.filePath)
                exist = true
            }
        })
        !exist && setImagePub("")
    }, [actualPub.id, pubs])

    const onChangePub = ({currentTarget}) => {
        const {id} = currentTarget
        setActualPub({id})
        setSimulateImagePub("")
        let uploadFile = document.getElementById("uploadFile")
        uploadFile.value = ""
    }

    const fileSelectedEvent = ({target}) => {
        var file = new FormData();
        file.append("file", target.files[0]);
        var fReader = new FileReader();
        if(target.files[0]){
            fReader.readAsDataURL(target.files[0]);
            fReader.onloadend = function({target}){
                setActualPub({...actualPub, file:true, dataFile: target.result, formData : file })
            }
        }
    }

    const simulatePub = () => {
        setSimulateImagePub(actualPub.dataFile)
        let uploadFile = document.getElementById("uploadFile")
        uploadFile.value = ""
    }

    const remplacePub = async () => {
        let response;
        try{
             response = await postFile(actualPub.formData)
        }catch(err){
            toast.error(err.message)
        }
        const {data} = response
        const id = toInteger(actualPub.id)
        updatePubs({id}, {
            image: data.id,
            status: true
        },
        () => {
            getPubs()
            setImagePub(REACT_APP_MEDIA_URL + data.filePath)
            setSimulateImagePub("")
        },
        (err) => {
            toast.error(err.message)
        }
        )
    }

    const deleteSimulatePub = () => {
        setSimulateImagePub("")
    }

    if (!Array.isArray(pubs)) return <Loader/>;

    return ( 
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className='card'>
                        <div className="card-header">
                            <NavBarPub actualPub={actualPub.id} changePub={onChangePub} />
                        </div>
                        <div className='card-body'>
                            <div className="row my-2">
                                <div className="col-lg-4">
                                    <h5>Publicité en cours</h5>
                                    <div className="pub bg-dark">
                                    {imagePub ?
                                        <img className="img-pub" src={imagePub} alt=""/>
                                        :
                                        <div className="img-pub" >Aucune pub actuellement </div>
                                    }
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h5>Publicité simulée</h5>
                                    <div className="pub bg-dark">
                                    {imageSimulatePub ?
                                        <img className="img-pub" src={imageSimulatePub} alt=""/>
                                        :
                                        <div className="img-pub" >Ajoutez une pub à simuler  </div>
                                    }
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="row">
                                        <h5>Sélectionner une image (.jpg, .png ...)</h5>
                                        <input type="file" id="uploadFile" name="file" onChange={fileSelectedEvent}/>
                                        <button onClick={simulatePub} className="btn btn-primary" disabled={!actualPub.file && "disabled"}>
                                            Simuler la pub
                                        </button>
                                    </div>
                                    <div className="btn-pub row">
                                        <div className="col-lg-6">
                                            <button className="btn btn-success" onClick={remplacePub} disabled={!imageSimulatePub && "disabled"}>
                                                Remplacer la pub
                                            </button>
                                        </div>
                                        <div className="col-lg-6">
                                            <button className="btn btn-danger" onClick={deleteSimulatePub} disabled={!imageSimulatePub && "disabled"}>
                                                Supprimer la simulation
                                            </button>
                                        </div>                                       
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
     );
}
const mapStateToProps = (state) => ({
    pubs: get(state, 'app.pubs.data', [])
});

export default compose(
    connect(() => mapStateToProps, {
        getPubs: createListAction(PUBS),
        updatePubs: createPutAction(PUBS),
    })
)(Publicites);
