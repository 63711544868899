export const LOGIN = "LOGIN";
export const OUBLI_PASS = "OUBLI_PASS";
export const RESET_PASS = "RESET_PASS";
export const USER = "USER";
export const USERS = "USERS";
export const ADMINS = "ADMINS";
export const MOUQABILS = "MOUQABILS";
export const SIGNALS = "SIGNALS";
export const ABONNEMENTS = "ABONNEMENTS";
export const PLANS = "PLANS";
export const FLASH_PLANS = "FLASH_PLANS";
export const PLANS_SETTINGS = "PLANS_SETTINGS";
export const EDITED_USER = "EDITED_USER";
export const APPROVED_USER = "APPROVED_USER";
export const TARIFS = "TARIFS";
export const NEW_SIGNALS = "NEW_SIGNALS";
export const PUBS = "PUBS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";


