export const adaptTarifsInitialValues = (plans, plans_settings, flash_plans) => {
    let initialValues = {};
    initialValues.settings = plans_settings[0];
    if (!plans[0]) return {};
    initialValues.oneMonth = plans[0];
    initialValues.oneMonth.pricePerMonth = parseFloat(initialValues.oneMonth.pricePerMonth).toFixed(2);
    initialValues.threeMonth = plans[1];
    initialValues.threeMonth.pricePerMonth = parseFloat(initialValues.threeMonth.pricePerMonth).toFixed(2);
    initialValues.year = plans[2];
    initialValues.year.pricePerMonth = parseFloat(initialValues.year.pricePerMonth).toFixed(2);
    initialValues.oneFlash = flash_plans[0];
    if (!flash_plans[0]) return {};
    initialValues.oneFlash.price = parseFloat(initialValues.oneFlash.price).toFixed(2);
    initialValues.fiveFlash = flash_plans[1];
    initialValues.fiveFlash.price = parseFloat(initialValues.fiveFlash.price).toFixed(2);
    initialValues.tenFlash = flash_plans[2];
    initialValues.tenFlash.price = parseFloat(initialValues.tenFlash.price).toFixed(2);
    return initialValues;
};
