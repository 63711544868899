import Admins from './index';
import AdminsForm from './form';

export default [
    {
        name: "Admins",
        path: "/admins",
        exact: true,
        component: Admins
    },
    {
        name: "Ajouter un admin",
        path: "/admins/ajouter",
        exact: true,
        component: AdminsForm
    },
    {
        name: "Modifier un admin",
        path: "/admins/modifier/:id",
        exact: true,
        component: AdminsForm
    },
]
