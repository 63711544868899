import React from 'react';

export default [
    {
        name: 'signaled',
        label: "Utilisateur",
        options: {
            filterType: "textField",
            customBodyRender: v => v.username
        }
    },
    {
        name: 'mouqabil',
        label: 'Signalé par',
        options: {
            filterType: "textField",
            customBodyRender: v => v.username
        }
    },
    {
        name: 'objet',
        label: 'Objet de signal',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "message",
        label: 'Message',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "status",
        label: 'Statut',
        options: {
            filterType: "multiselect",
            customBodyRender: v => v ? <span style={{color: 'red'}}>Terminé</span> : <span style={{color: 'green'}}>Ouvert</span>
        }
    },
];
