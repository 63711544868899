import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {Field, reduxForm} from "redux-form";
import {get} from 'lodash';
import { required, email } from 'redux-form-validators'
import {toast} from "react-toastify";

import {createGetAction, createPostAction, createPutAction} from "../../redux/actions";
import {ADMINS, USERS} from "../../redux/actions/constants";
import {renderInputField, renderSelect2Async} from "../../components/Redux-Forms";

const AdminsForm = (props) => {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (props.computedMatch.params.id) {
            setEdit(true);
            props.getAdmin({id: get(props, 'computedMatch.params.id')});
        }
    }, []);

    const createOrUpdateAdmin = (values) => {
        if (edit) {
            props.updateAdmin({id: get(props, 'computedMatch.params.id')}, values,
                () => props.history.push("/admins"),
                ({err}) => toast.error(err.message)
            );
        }
        else props.createAdmin(null, values,
            () => props.history.push("/admins"),
            ({err}) => toast.error(err.message)
        );
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <form onSubmit={props.handleSubmit(createOrUpdateAdmin)}>
                    <div className='my-4 row'>
                        <div className='col-12'>
                            <div className='card'>
                                <h4 className="card-header">{edit ? "Modifier un admin" : "Ajouter un admin"}</h4>
                                <div className='card-body m-4'>
                                    <div className='row'>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Nom</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="lastName"
                                                    component={renderInputField}
                                                    type="text"
                                                    label="Nom"
                                                    validate={[required()]}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Prénom</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="firstName"
                                                    component={renderInputField}
                                                    type="text"
                                                    label="Prénom"
                                                    validate={[required()]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Adresse e-mail</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="email"
                                                    component={renderInputField}
                                                    type="text"
                                                    label="Adresse e-mail"
                                                    validate={[required(), email()]}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Mot de passe</label>
                                            <div>
                                                <Field
                                                    className='form-control'
                                                    name="password"
                                                    component={renderInputField}
                                                    type="password"
                                                    label="Mot de passe"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>Profil</label>
                                            <div>
                                                <Field
                                                    name={`profil`}
                                                    component={renderSelect2Async}
                                                    noOptionsMessage={'Aucun profil trouvé.'}
                                                    options={[{label: "Admin 1", value: "Admin 1"}]}
                                                    className="form-control"
                                                    placeholder={'Sélectionnez un profil'}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group col-md-6 col-sm-12'>
                                            <label className='font-weight-bold'>App</label>
                                            <div>
                                                <Field
                                                    name={`app`}
                                                    component={renderSelect2Async}
                                                    noOptionsMessage={'Aucunne app trouvée.'}
                                                    options={[{label: "Mouqabala", value: "Mouqabala"}]}
                                                    className="form-control"
                                                    placeholder={'Sélectionnez une app'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className=' col-12 d-flex flex-row-reverse mt-3'>
                                            <button
                                                className={'btn btn-primary ml-1'}
                                                type="submit"
                                                disabled={
                                                    props.pristine ||
                                                    props.submitting
                                                }
                                            >
                                                Enregistrer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        initialValues: get(state, 'app.users.one', {})
    }
};

export default compose(
    connect(() => mapStateToProps, {
        createAdmin: createPostAction(ADMINS),
        getAdmin: createGetAction(USERS),
        updateAdmin: createPutAction(USERS)
    }),
    reduxForm({form: 'adminsForm', enableReinitialize: true}),
)(AdminsForm);
