import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { createPutAction } from '../../redux/actions';
import { SEND_NOTIFICATION } from '../../redux/actions/constants';
import "./index.css";

const Communication = ({ sendNotification }) => {



    const [newNotif, setNewNotif] = useState({
        message: "",
        target: "HF",
        now: true,
        date: null,
        time: null,
        notif: true,
        email: false,
        ageMin: null,
        ageMax: null,
        subscriber: ""
    })
    const [validForm, setValidForm] = useState(false)
    const [newFilter, setNewFilter] = useState({ state: false })
    const [modifFilter, setModifFilter] = useState("")

    useEffect(() => {
        if (newNotif.message === "" || (!newNotif.now && (!newNotif.date || !newNotif.time)) || (!newNotif.email && !newNotif.notif)) {
            setValidForm(false)
        } else {
            setValidForm(true)
        }

    }, [newNotif])

    const actionsFilterWriters = [
        {
            id: "valid",
            action: 'validate',
            icon: ' fas fa-check',
            color: 'success',
            name: 'Valider',
            onClick: () => onValidateFilter()
        }
    ]
    const actionsFilterReadOnly = [

        {
            id: "del",
            action: 'delete',
            icon: 'fa fa-trash-alt',
            name: 'Supprimer',
            color: 'danger',
            onClick: (id) => deleteFilter(id)
        },
        {
            id: "edit",
            action: 'edit',
            icon: ' fa fa-pen',
            color: 'warning',
            name: 'Modifier',
            onClick: (id) => setModifFilter(id)
        }
    ];
    const list_filters = [
        {
            libelle: "Age Minimum",
            id: "ageMin",
            type: "number"
        },
        {
            libelle: "Age Maximum",
            id: "ageMax",
            type: "number"
        },
        {
            libelle: "Abonné(e)",
            id: "subscriber",
            options: [{ libelle: "Oui", id: "Y" }, { libelle: "Non", id: "N" }]
        }
    ]

    const validate = () => {
        const { target, ageMax, ageMin, message, subscriber, date, time } = newNotif
        const dateProgram = new Date(date + " " + time)
        sendNotification("", {
            gender: target === "HF" ? "" : target === "H" ? "M" : "Mme",
            maxAge: ageMax,
            minAge: ageMin,
            isSubscriber: subscriber ?? null,
            contents: message,
            dateProgram
        },
            (data) => {
                toast.success(data + " Notifications envoyées")
            },
            (err) => {
                toast.error(err)
            }
        )
    }



    const onHandleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget
        setNewNotif({ ...newNotif, [name]: value })
    }

    const deleteFilter = (id) => {
        setNewNotif({ ...newNotif, [id]: null })
    }

    const onhandleSelected = ({ currentTarget }) => {

        const { value } = currentTarget
        list_filters.forEach(f => {
            if (f.id === value) {
                if (f.options) {
                    setNewFilter({
                        ...newFilter,
                        name: value,
                        options: f.options,
                        type: f.type,
                        value: f.options[0].id
                    })
                } else {
                    console.log(f)
                    setNewFilter({ ...newFilter, name: value, options: null, type: f.type, value: undefined })
                }
            }

        })
    }

    const onValidateFilter = () => {
        if (newFilter.state) {
            setNewNotif({ ...newNotif, [newFilter.name]: newFilter.value })
            setNewFilter({ state: false })
        } else {
            setModifFilter("")
        }
    }


    let filters = []
    let filtersNoSelected = []
    let i = true;
    list_filters.forEach(filter => {
        if (newNotif[filter.id] || modifFilter === filter.id) {
            filters.push({
                id: filter.id,
                libelle: filter.libelle,
                value: newNotif[filter.id],
                options: filter.options,
                type: filter.type
            })

        } else {
            filtersNoSelected.push(filter)
            if (!newFilter.name && i) {
                const value = filter.options ? filter.options[0].id : ""
                console.log(filter)
                setNewFilter({
                    ...newFilter,
                    name: filter.id,
                    options: filter.options,
                    value,
                    type: filter.type
                })
                i = false
            }
        }
    })

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className='card'>
                        <h4 className="card-header">Ecrire ici votre message</h4>
                        <div className='card-body m-4'>
                            <div className='row'>
                                <div className='form-group col-md-6 col-sm-12'>
                                    <textarea
                                        className='form-control'
                                        name="message"
                                        value={newNotif.message}
                                        onChange={onHandleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-lg-12">
                    <div className='card'>
                        <h4 className="card-header">Sélectionner une audience</h4>
                        <div className='card-body m-4'>
                            <div className='row'>
                                <div className="col-lg-4 gender">
                                    <div className={"btn btn-" + (newNotif.target === "H" ? "primary" : "light")}
                                        onClick={() => setNewNotif({ ...newNotif, target: "H" })}>
                                        <i className="h1 fas fa-male item"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 gender" >
                                    <div
                                        className={"btn btn-" + (newNotif.target === "F" ? "primary" : "light")}
                                        onClick={() => setNewNotif({ ...newNotif, target: "F" })}>
                                        <i className="h1 fas fa-female item"></i>
                                    </div>
                                </div>
                                <div className="col-lg-4 gender" >
                                    <div
                                        className={"btn btn-" + (newNotif.target === "HF" ? "primary" : "light")}
                                        onClick={() => setNewNotif({ ...newNotif, target: "HF" })}>
                                        <i className="h1 fas fa-users item"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <h5>Filtre(s) supplémentaires :</h5>
                            </div>
                            <div className="row my-4">
                                <table className="col-lg-12 table table-striped">
                                    <tbody>
                                        {filters.map(filter =>
                                            filter.id !== modifFilter ?
                                                <tr key={"filter-" + filter.id}>
                                                    <td scope="row">{filter.libelle}</td>
                                                    <td><p style={{ float: "left" }}>{filter.value === "Y" ? "Oui" : filter.value === "N" ? "Non" : filter.value}</p></td>
                                                    <td>{actionsFilterReadOnly.map((action) =>
                                                        <button
                                                            style={{ float: "right" }}
                                                            data-toggle="tooltip" data-placement="bottom" title={action.name}
                                                            key={action.id + "-" + filter.id}
                                                            className={'btn btn-outline btn-light ml-1 border-0 btn-outline-' + action.color}
                                                            onClick={() => action.onClick(filter.id)}
                                                        >
                                                            <i className={action.icon} />
                                                        </button>
                                                    )}</td>
                                                </tr>
                                                :
                                                <tr key={"filter-" + filter.id}>
                                                    <td scope="row">{filter.libelle}</td>
                                                    <td>
                                                        {!filter.options ?
                                                            <input name={filter.id} type={filter.type} value={filter.value} onChange={onHandleChange} />
                                                            :
                                                            <select className="form-control form-control-sm" value={filter.value} onChange={({ currentTarget }) => setNewNotif({ ...newNotif, [filter.id]: currentTarget.value })}>
                                                                {filter.options.map(option =>
                                                                    <option key={"option-" + option.id} value={option.id} >{option.libelle}</option>
                                                                )}
                                                            </select>
                                                        }
                                                    </td>
                                                    <td>{actionsFilterWriters.map((action) =>
                                                        <button
                                                            style={{ float: "right" }}
                                                            data-toggle="tooltip" data-placement="bottom" title={action.name}
                                                            key={action.id + "-" + filter.id}
                                                            className={'btn btn-outline btn-light ml-1 border-0 btn-outline-' + action.color}
                                                            onClick={() => action.onClick(filter.id)}
                                                        >
                                                            <i className={action.icon} />
                                                        </button>
                                                    )}</td>
                                                </tr>


                                        )}
                                        {newFilter.state &&
                                            <tr>
                                                <td>
                                                    <select className="form-control form-control-sm" value={newFilter.name} onChange={onhandleSelected}>
                                                        {filtersNoSelected.map(filter =>
                                                            <option key={"filterSelect-" + filter.id} value={filter.id} >{filter.libelle}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td>{!newFilter.options ?
                                                    <input value={newFilter.value} type={newFilter.type} onChange={({ currentTarget }) => setNewFilter({ ...newFilter, value: currentTarget.value })} />
                                                    :
                                                    <select className="form-control form-control-sm" value={newFilter.value} onChange={({ currentTarget }) => setNewFilter({ ...newFilter, value: currentTarget.value })}>
                                                        {newFilter.options.map(option =>
                                                            <option key={"option-" + option.id} value={option.id} >{option.libelle}</option>
                                                        )}
                                                    </select>
                                                }
                                                </td>
                                                <td>{actionsFilterWriters.map((action) =>
                                                    <button
                                                        style={{ float: "right" }}
                                                        data-toggle="tooltip" data-placement="bottom" title={action.name}
                                                        key={action.id + "-" + 1}
                                                        className={'btn btn-outline btn-light ml-1 border-0 btn-outline-' + action.color}
                                                        onClick={() => action.onClick()}
                                                    >
                                                        <i className={action.icon} />
                                                    </button>
                                                )}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {filtersNoSelected.length !== 0 && !newFilter.state &&
                                <div className="row">
                                    <div className="btn btn-primary" onClick={() => setNewFilter({ ...newFilter, state: true })}>Ajouter un filtre</div>

                                </div>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-lg-12">
                    <div className='card'>
                        <h4 className="card-header">Paramétrer l'envoi</h4>
                        <div className='card-body m-4'>
                            <div className='row my-2'>
                                <div className="col-lg-4" >
                                    <div
                                        onClick={() => setNewNotif({ ...newNotif, now: true })}
                                        className={"btn btn-" + (newNotif.now ? "primary" : "light")}>
                                        Immédiat
                                    </div>
                                </div>
                                <div className="col-lg-4" >
                                    <div
                                        onClick={() => setNewNotif({ ...newNotif, now: false })}
                                        className={"btn btn-" + (!newNotif.now ? "primary" : "light")}>
                                        Plus tard
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <input type="date" name="date" onChange={onHandleChange} value={newNotif.date} disabled={newNotif.now && "disabled"} />
                                    <input type="time" name="time" onChange={onHandleChange} value={newNotif.time} disabled={newNotif.now && "disabled"} />
                                </div>

                            </div>
                            {/* <div className="row my-2">
                                <div className="col-lg-4" >
                                    <div
                                        onClick={() => setNewNotif({ ...newNotif, notif: !newNotif.notif })}
                                        className={"btn btn-" + (newNotif.notif ? "primary" : "light")}>
                                        Notification
                                    </div>
                                </div>
                                <div className="col-lg-4" >
                                    <div
                                        onClick={() => setNewNotif({ ...newNotif, email: !newNotif.email })}
                                        className={"btn btn-" + (newNotif.email ? "primary" : "light")}>
                                        Mail
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className=' col-12 d-flex flex-row-reverse mt-3'>
                            <button
                                className={'btn btn-primary ml-1'}
                                type="submit"
                                disabled={!validForm && "disabled"}
                                onClick={validate}
                            >
                                Valider l'envoi
                            </button>
                            <button
                                className={'btn btn-secondary ml-1'}
                                type="button"
                                disabled="disabled"
                            >
                                Sortir sans modifier
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default compose(
    connect(() => { }, {
        sendNotification: createPutAction(SEND_NOTIFICATION),
    })
)(Communication);