import React from 'react';
import './App.css';
import {BrowserRouter, HashRouter, Redirect, Route, Switch} from "react-router-dom";
import AppWrapper from "./Layout/AppWrapper";
import Login from "./pages/auth/login";
import LogOut from './pages/auth/logout';
import ForgotPassword from './pages/auth/forgotPassword';
import Sample from './pages/Sample';

function App() {
  return (
      <div id="wrapper" className={"app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header"}>
        <HashRouter>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/forgotPassword/:token" component={ForgotPassword}/>
            <Route exact path="/forgotPassword" component={ForgotPassword}/>
            <Route exact path="/logout" component={LogOut}/>
            <Route path="/" render={(props) => {
              return <AppWrapper {...props} />
            }}/>
          </Switch>
        </HashRouter>
      </div>
  );
}

export default App;
