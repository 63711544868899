import React from 'react';
import moment from "moment";

export const newUsersColumns = [
    {
        name: 'username',
        label: "Nom d'utilisateur",
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'birthday',
        label: 'Date de naissance',
        options: {
            filterType: "textField",
            customBodyRender: v => moment(v).format("DD / MM / YYYY")
        }
    },
    {
        name: 'liveCountry',
        label: 'Pays',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "phone",
        label: 'Numéro de téléphone',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "registrationDate",
        label: "Date d'inscription",
        options: {
            filterType: "textField",
            customBodyRender: v => {
                return v ? moment(v).format("DD / MM / YYYY") : "N/A"
            }
        }
    },
];

export const editUsersColumns = [
    {
        name: 'username',
        label: "Nom d'utilisateur",
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'birthday',
        label: 'Date de naissance',
        options: {
            filterType: "textField",
            customBodyRender: v => moment(v).format("DD / MM / YYYY")
        }
    },
    {
        name: 'liveCountry',
        label: 'Pays',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "phone",
        label: 'Numéro de téléphone',
        options: {
            filterType: "textField",
        }
    },
    {
        name: "editedField",
        label: "Champs modifiés",
        options: {
            filterType: "textField",
            customBodyRender: v => {
                const fields = v.split(',');
                let result = [];
                if (fields.includes('about')) result.push("A props");
                if (fields.includes('project')) result.push("Ses projets");
                if (fields.includes('pics')) result.push("Ses Photos");
                return result.join(", ");
            }
        }
    },
];
