import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import sideBarNavs from "./sideBarNavs";
import {get} from "lodash";
import {connect} from "react-redux";
import {createListAction} from "../../redux/actions";
import {APPROVED_USER, NEW_SIGNALS} from "../../redux/actions/constants";

const SideBar = ({history, getUsersToApprove, usersToApprove, getnewSignals, newSignals}) => {
    const [activeSubModule, setActiveSubModule] = useState(sideBarNavs[0]);

    useEffect(() => {
        getUsersToApprove();
        getnewSignals();
    }, []);

    return (
        <div className="app-sidebar sidebar-shadow">
            <div className="app-header__logo">
                <div className="logo-src"/>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic"
                                data-class="closed-sidebar">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"/>
                                    </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"/>
                                </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                        <span>
                            <button type="button"
                                    className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"/>
                                </span>
                            </button>
                        </span>
            </div>
            <div className="scrollbar-sidebar">
                <div className="app-sidebar__inner">
                    <ul className="vertical-nav-menu mt-3">
                        {
                            sideBarNavs.map((nav, i) => {
                                return (
                                    <li className="mb-2" key={i}
                                        onClick={() => setActiveSubModule(nav)}
                                    >
                                        <NavLink className={"mm" + (nav === activeSubModule ? "-active" : "")}
                                                 to={nav.url}>
                                            <i className={"metismenu-icon " + nav.icon}> </i>
                                            {nav.name}
                                            {
                                                (nav.name === "Validations" && usersToApprove.length) ? <>
                                                    <button type="button"
                                                            className="btn-shadow p-1 btn btn-primary btn-sm ml-3">
                                                        <i className="fa text-white fa-bell pr-1 pl-1"/>
                                                        <span className="fa fa-comment"/>
                                                        <span className="num">{usersToApprove.length}</span>
                                                    </button>
                                                </> : <></>
                                            }
                                            {
                                                (nav.name === "Modération" && newSignals.length) ? <>
                                                    <button type="button"
                                                            className="btn-shadow p-1 btn btn-primary btn-sm ml-3">
                                                        <i className="fa text-white fa-bell pr-1 pl-1"/>
                                                        <span className="fa fa-comment"/>
                                                        <span className="num">{newSignals.length}</span>
                                                    </button>
                                                </> : <></>
                                            }
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};



const mapStateToProps = (state) => ({
    usersToApprove: get(state, 'app.approved.data', []),
    newSignals: get(state, 'app.newSignals.data', [])
});

export default connect(mapStateToProps, {
    getUsersToApprove: createListAction(APPROVED_USER),
    getnewSignals: createListAction(NEW_SIGNALS),
})(SideBar);
