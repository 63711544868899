import Communication from './index';

export default [
    {
        name: "Communication",
        path: "/communication",
        exact: true,
        component: Communication
    }
]
