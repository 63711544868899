import Header from "../Header";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {isEmpty, get} from "lodash";

import SideBar from "../SideBar";
import Container from "../Container";
import Loader from "../Loader";
import {createGetAction} from "../../redux/actions";
import {USER} from "../../redux/actions/constants";
import { Redirect } from "react-router-dom";

const AppWrapper = ({history, user, getConnectedUser}) => {
    useEffect(() => {
        getConnectedUser()
    }, []);

    if(!localStorage.getItem("token")) {
        return <Redirect to="/login" />
    } 
    return (
        <>
            <Header
                    history={history} user={user}/>
            <div className="app-main">
                <SideBar history={history}/>
                <Container history={history} user={user}/>
            </div>
        </>
    )
};

export default connect(({app}) => ({user: get(app, 'user.one')}), {getConnectedUser: createGetAction(USER)})(AppWrapper);
