import React, { useEffect } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import swal from "sweetalert";

import Loader from "../../Layout/Loader";
import Datatable from "../../components/Datatable";
import columns from "./columns";
import { createListAction, createPutAction } from "../../redux/actions";
import { MOUQABILS } from "../../redux/actions/constants";

const Utilisateurs = ({
  utilisateurs,
  history,
  getUtilisateurs,
  updateUtilisateur,
}) => {
  useEffect(() => {
    getUtilisateurs();
  }, [getUtilisateurs]);

  const actions = [
    {
      action: "show",
      icon: " fa fa-eye",
      color: "info",
      name: "Afficher",
      onClick: (id) => history.push("/utilisateurs/details/" + id),
    },
    {
      action: "delete",
      icon: "fa fa-ban",
      name: "Bannir",
      color: "danger",
      onClick: (id) => banorDebanUser(id),
    },
  ];

  const banorDebanUser = (id) => {
    const user = utilisateurs.find((u) => u.id === id);
    if (user.status === "Banni") {
      swal({
        title: "Êtes-vous sûr?",
        text:
          "Voulez-vous vraiment annuler le bannissement de l'utilisateur " +
          user.username +
          " ?",
        icon: "warning",
        buttons: {
          cancel: "Annuler",
          terminer: true,
        },
      }).then((willBan) => {
        if (willBan) {
          updateUtilisateur({ id }, { isSuspended: false }, () => {
            getUtilisateurs();
            swal(
              "L'interdiction de l'utilisateur " +
                user.username +
                " est terminée!",
              {
                icon: "success",
              }
            );
          });
        }
      });
    } else
      swal({
        title: "Êtes-vous sûr?",
        text:
          "Voulez-vous vraiment bannir l'utilisateur " + user.username + " ?",
        icon: "warning",
        buttons: {
          cancel: "Annuler",
          bannir: true,
        },
      }).then((willBan) => {
        if (willBan) {
          updateUtilisateur(
            { id },
            { isSuspended: true, isApproved: true },
            () => {
              getUtilisateurs();
              swal("L'utilisateur a été banni!", {
                icon: "success",
              });
            }
          );
        }
      });
  };

  if (!Array.isArray(utilisateurs)) return <Loader />;

  return (
    <div className="row my-4">
      <div className="col-lg-12">
        <Datatable
          data={utilisateurs}
          title={"Gestion des utilisateurs"}
          columns={columns}
          actions={actions}
          options={{ selectableRows: "none" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    utilisateurs: get(state, "app.mouqabils.data", []),
  };
};

export default connect(mapStateToProps, {
  getUtilisateurs: createListAction(MOUQABILS),
  updateUtilisateur: createPutAction(MOUQABILS),
})(Utilisateurs);
