import React from 'react';
import Loader from '../../Layout/Loader';

const LogOut = ({history}) => {
    localStorage.clear()
    history.push("/login")

    return ( 
        <Loader />
     );
}
 
export default LogOut;