import React from 'react';

export default [
    {
        name: 'firstname',
        label: 'PRENOM',
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'lastname',
        label: 'NOM',
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'email',
        label: 'ADRESSE MAIL',
        options: {
            filterType: "textField",
        }
    },
    {
        name: 'app',
        label: 'APP',
        options: {
            filterType: "multiselect",
        }
    },
    {
        name: 'profil',
        label: 'PROFIL',
        options: {
            filterType: "multiselect",
        }
    },
];
