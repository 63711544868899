import Signals from './index';

export default [
    {
        name: "Modération",
        path: "/moderation",
        exact: true,
        component: Signals
    }
]
