import React, {useEffect, useState} from 'react';
import {get} from "lodash";
import {connect} from "react-redux";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge} from 'reactstrap';

import Loader from "../../Layout/Loader";
import Datatable from "../../components/Datatable";
import columns from "./columns";
import {createDeleteAction, createListAction, createPutAction} from "../../redux/actions";
import {MOUQABILS, SIGNALS} from "../../redux/actions/constants";
import swal from "sweetalert";
import {Link} from "react-router-dom";

const Signals = ({signals, history, getSignals, updateUtilisateur, updateSignal, deleteSignal}) => {

    const [modal, setModal] = useState(false);
    const [selectedSignal, setSelectedSignal] = useState({});

    const toggle = (id) => {
        if (!isNaN(id)) {
            const signal = signals.find(s => s.id === id);
            setSelectedSignal(signal);
        } else setSelectedSignal({});
        setModal(!modal);
    };

    useEffect(() => {
        getSignals();
    }, []);

    const actions = [
        {
            action: 'show',
            icon: ' fa fa-eye',
            color: 'info',
            name: 'Afficher',
            onClick: (id) => toggle(id)
        },
        {
            action: 'delete',
            icon: 'fa fa-trash-alt',
            name: 'Supprimer',
            color: 'danger',
            onClick: (id) => confirmDeleteSignal(id)
        }
    ];

    const confirmDeleteSignal = (id) => {
        swal({
            title: "Êtes-vous sûr?",
            text: "Une fois supprimé, vous ne pourrez plus récupérer ce signal!",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                supprimer: true
            }
        }).then((willDelete) => {
            if (willDelete) {
                deleteSignal({id}, () => {
                    getSignals();
                    swal("Le signal a été supprimé!", {
                        icon: "success",
                    });
                });
            }
        });
    };

    const ban = () => {
        swal({
            title: "Êtes-vous sûr?",
            text: "Voulez-vous vraiment bannir l'utilisateur " + selectedSignal.signaled.username + " ?",
            icon: "warning",
            buttons: {
                cancel: "Annuler",
                bannir: true
            }
        }).then((willBan) => {
            if (willBan) {
                updateUtilisateur({id: selectedSignal.signaled.id},
                    {isSuspended: true}
                    , () => {
                        updateSignal({id: selectedSignal.id},
                            {status: true},
                            () => {
                                getSignals();
                                swal("L'utilisateur a été banni!", {
                                    icon: "success",
                                });
                                toggle();
                            });
                    });
            }
        });
    };

    if (!Array.isArray(signals)) return <Loader/>;

    return (
        <div className="row">
            <div className="col-lg-12">
                <Datatable data={signals}
                           title={"Modération"}
                           columns={columns}
                           actions={actions}
                           options={{
                               selectableRows: 'none'
                           }}
                />
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {selectedSignal.objet}{' '}
                    {selectedSignal.status ? <sup><Badge color="success">Terminé</Badge></sup> :
                        <sup><Badge color="danger">Ouvert</Badge></sup>}
                </ModalHeader>
                <ModalBody>
                    <div className='row mt-3'>
                        <div className="col-3 pr-0 secondary-text">Utilisateur :</div>
                        <div className="col-9 pl-0">
                            {
                                selectedSignal.signaled && <Link
                                    to={"/utilisateurs/details/" + selectedSignal.signaled.id}>{selectedSignal.signaled && selectedSignal.signaled.username}</Link>
                            }
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-3 pr-0 secondary-text">Signalé par :</div>
                        <div className="col-9 pl-0">{selectedSignal.mouqabil && selectedSignal.mouqabil.username}</div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-3 pr-0 secondary-text">Message :</div>
                        <div className="col-9 pl-0">{selectedSignal.message}</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {selectedSignal.status || <Button color="danger" onClick={ban}>Bannir</Button>}
                    <Button color="secondary" onClick={toggle}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        signals: get(state, 'app.signals.data', [])
    }
};

export default connect(mapStateToProps, {
    getSignals: createListAction(SIGNALS),
    updateSignal: createPutAction(SIGNALS),
    updateUtilisateur: createPutAction(MOUQABILS),
    deleteSignal: createDeleteAction(SIGNALS)
})(Signals);
