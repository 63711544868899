import { GET, LIST, POST, PUT } from "./actions/actionTypes";
import {
    ADMINS,
    LOGIN,
    USER,
    MOUQABILS,
    SIGNALS,
    USERS,
    ABONNEMENTS,
    PLANS,
    PLANS_SETTINGS,
    FLASH_PLANS, EDITED_USER, APPROVED_USER, TARIFS, NEW_SIGNALS, PUBS, OUBLI_PASS, RESET_PASS, SEND_NOTIFICATION
} from "./actions/constants";
import { createCrudActions } from "./actions";

export default [
    {
        name: POST(LOGIN),
        url: "/authentication_token",
        reducerPath: "auth",
        method: "POST",
    },
    {
        name: POST(OUBLI_PASS),
        url: "/api/oubliPass",
        reducerPath: "oubli_pass",
        method: "POST",
    },
    {
        name: POST(RESET_PASS),
        url: "/api/resetPassword",
        reducerPath: "reset_pass",
        method: "POST",
    },
    {
        name: POST(ADMINS),
        url: "/api/admin/register",
        reducerPath: "auth_admin",
        method: "POST",
    },
    {
        name: GET(USER),
        url: "/api/currentUser",
        reducerPath: "user",
        method: "GET",
    },
    {
        name: LIST(ADMINS),
        url: "/api/admins",
        reducerPath: "admins",
        method: "GET",
    },
    {
        name: LIST(MOUQABILS),
        url: "/api/customMouqabils",
        reducerPath: "mouqabils",
        method: "GET",
    },
    {
        name: LIST(EDITED_USER),
        url: "/api/mouqabils?edit=true",
        reducerPath: "edits",
        method: "GET",
    },
    {
        name: LIST(APPROVED_USER),
        url: "/api/mouqabils?isApproved=false",
        reducerPath: "approved",
        method: "GET",
    },
    {
        name: LIST(NEW_SIGNALS),
        url: "/api/signals?status=false",
        reducerPath: "newSignals",
        method: "GET",
    },
    {
        name: LIST(PUBS),
        url: "/api/pubs",
        reducerPath: "pubs",
        method: "GET",
    },
    {
        name: PUT(TARIFS),
        url: "/api/tarifs",
        reducerPath: "tarifs",
        method: "PUT",
    },
    {
        name: PUT(SEND_NOTIFICATION),
        url: "/api/sendNotification",
        reducerPath: "sendNotification",
        method: "PUT",
    },
    ...createCrudActions(MOUQABILS),
    ...createCrudActions(USERS),
    ...createCrudActions(ABONNEMENTS),
    ...createCrudActions(PLANS),
    ...createCrudActions(PLANS_SETTINGS),
    ...createCrudActions(FLASH_PLANS),
    ...createCrudActions(SIGNALS),
    ...createCrudActions(PUBS)
]
