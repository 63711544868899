import Tarifs from './index';

export default [
    {
        name: "Grille tarifaire",
        path: "/grille",
        exact: true,
        component: Tarifs
    }
]
