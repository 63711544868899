import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { get } from 'lodash';
import { toast } from "react-toastify";

import "./style.css";
import { createPostAction } from "../../redux/actions";
import { LOGIN, OUBLI_PASS, RESET_PASS } from "../../redux/actions/constants";

const ForgotPassword = ({  oubliPassword, history, match, testToken, resetPassword }) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tokenValid, setTokenValid] = useState();
    const token = localStorage.getItem('token');
    const [isConnected, setIsConnected] = useState(false)

    useEffect(() => {
        if (match.params.token) {
            console.log(match.params.token)
            setIsSubmitting(true)
            testToken(null, {token:match.params.token}, (res) => {
                console.log(res)
                setIsSubmitting(false);
                setTokenValid(match.params.token)                   
            }, (err) => {
                console.log(err)
                toast(get(err, "message"), { type: "error" });
                setIsSubmitting(false);
            })
        }
    }, [])

    useEffect(() => {
        if (token) setIsConnected(true)
    }, [isSubmitting])

    if (isConnected) {
        history.push("/");
    }


    const submitMail = () => {
        setIsSubmitting(true);
        oubliPassword(null, { email }, (res) => {
            toast("Un mail à était envoyé !", { type: "success" });
            setIsSubmitting(false);
        }, (err) => {
            toast(get(err, "message"), { type: "error" });
            setIsSubmitting(false);
        })
    };

    const submitPassword = () => {
        setIsSubmitting(true);
        resetPassword(null, {token:tokenValid, password }, (res) => {
            toast("Mot de passe modifier avec succés !", { type: "success" });
            setIsSubmitting(false);
        }, (err) => {
            console.log(err)
            toast(get(err, "message"), { type: "error" });
            setIsSubmitting(false);
        })
    }


    return (
        tokenValid ?
            <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                <div className="card card0 border-0 ">
                    <div className="row d-flex align-items-center h-85">
                        <div className="col-lg-6">
                            <div className="card1 pb-5">
                                <div className="row" />
                                <div className="row px-3 justify-content-center mt-4 mb-5 border-line align-middle">
                                    <img src={require("../../assets/images/logo-xlg.png")} className="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card2 card border-0 px-4 py-5">
                                <div className="row px-3"><label className="mb-1">
                                    <h6 className="mb-0 text-sm">Nouveau Mot de passe</h6>
                                </label> <input className="mb-4" type="password" name="email" onChange={(e) => setPassword(e.target.value)} /></div>
                                <div className="row px-3"><label className="mb-1">
                                    <h6 className="mb-0 text-sm">Confirmer Mot de passe</h6>
                                </label> <input className="mb-4" type="password" name="email" onChange={(e) => setConfirmPassword(e.target.value)} /></div>
                                <div className="row mb-3 px-3"> <button
                                    className="btn btn-blue text-center"
                                    disabled={isSubmitting || !password || (confirmPassword !== password)}
                                    onClick={submitPassword}
                                >
                                    Valider</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-blue py-4">
                        <div className="row px-3 justify-content-end">
                            <small className="mr-4 mr-sm-5 mb-2">Copyright &copy; {moment().format("YYYY")}. Tous les droits
                            sont réservés.
                        </small>
                        </div>
                    </div>
                </div>
            </div>
            :
            
            <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                <div className="card card0 border-0 ">
                    <div className="row d-flex align-items-center h-85">
                        <div className="col-lg-6">
                            <div className="card1 pb-5">
                                <div className="row" />
                                <div className="row px-3 justify-content-center mt-4 mb-5 border-line align-middle">
                                    <img src={require("../../assets/images/logo-xlg.png")} className="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card2 card border-0 px-4 py-5">
                                <div className="row px-3"><label className="mb-1">
                                    <h6 className="mb-0 text-sm">Email</h6>
                                </label> <input className="mb-4" type="text" name="email" onChange={(e) => setEmail(e.target.value)} /></div>
                                <div className="row mb-3 px-3"> <button
                                    className="btn btn-blue text-center"
                                    disabled={isSubmitting || !email}
                                    onClick={submitMail}
                                >
                                    Envoyez un mail</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-blue py-4">
                        <div className="row px-3 justify-content-end">
                            <small className="mr-4 mr-sm-5 mb-2">Copyright &copy; {moment().format("YYYY")}. Tous les droits
                            sont réservés.
                        </small>
                        </div>
                    </div>
                </div>
            </div>
    )
};



export default connect(({ user }) => ({ user }), { oubliPassword: createPostAction(OUBLI_PASS), testToken: createPostAction(RESET_PASS), resetPassword: createPostAction(RESET_PASS) })(ForgotPassword);
