import React from 'react';
import Sample from "./pages/Sample";
import adminsRoutes from "./pages/Admins/routes"
import utilisateursRoutes from "./pages/Utilisateurs/routes"
import moderationsRoutes from "./pages/Moderations/routes"
import tarifsRoutes from "./pages/Tarifs/routes"
import validationsRoutes from "./pages/Validations/routes"
import communicationsRoutes from "./pages/Communications/routes"
import publicitesRoutes from "./pages/Publicites/routes"

export default [
    ...adminsRoutes,
    ...utilisateursRoutes,
    ...moderationsRoutes,
    ...tarifsRoutes,
    ...validationsRoutes,
    ...communicationsRoutes,
    ...publicitesRoutes,
    {
        name: "Sample",
        path: "/",
        exact: true,
        component: Sample
    }
];
